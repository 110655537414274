<template>
  <div style="margin-top:20px;">
    <div v-if="specialty.degree == 1">
      <p class="title">主要对应职业类别</p>
      <p v-if="specialty.zydyzy" class="intro-content">{{ specialty.zydyzy }}</p>
      <p v-else class="intro-content">暂无！</p>
    </div>

    <div v-else>
      <p class="title">职业分布</p>
      <p v-if="specialty.zhiyefenbu" class="intro-content">{{ specialty.zhiyefenbu }}</p>
      <p v-else class="intro-content">暂无！</p>
    </div>

    <el-row :gutter="30">
      <el-col v-if="specialty.degree == 0" :span="12">
        <p class="title" style="margin-bottom:30px">就业率</p>
        <div class="box">
          <el-progress :stroke-width="15" :percentage="Math.floor(num2*100)" type="circle" />
        </div>
      </el-col>
      <el-col v-if="specialty.degree == 0" :span="12">
        <p class="title" style="margin-bottom:30px">深造率(研究生/留学)</p>
        <div class="box">
          <el-progress :stroke-width="15" :percentage="Math.floor(num1*100)" type="circle" />
        </div>
      </el-col>
      <div v-if="specialty.degree == 1" style="height:230px; margin-left: 15px;
    margin-right: 15px;">
        <p class="title" style="margin-bottom:30px">近三年升本率</p>
        <el-col v-if="specialty.degree == 1" :span="8">
          <div class="box">
            <el-progress :stroke-width="15" :percentage=" Number(specialty.shengben1) " type="circle" />
          </div>
        </el-col>
        <el-col :span="8">
          <div class="box">
            <el-progress :stroke-width="15" :percentage="Number(specialty.shengben2 )" type="circle" />
          </div>
        </el-col>
        <el-col :span="8">
          <div class="box">
            <el-progress :stroke-width="15" :percentage="Number(specialty.shengben3)" type="circle" />
          </div>
        </el-col>
      </div>

      <div v-if="specialty.degree == 1" style="margin-left: 15px;
    margin-right: 15px;">
        <p class="title" style="margin-bottom:30px">近三年就业率</p>
        <el-col :span="8">
          <div class="box">
            <el-progress :stroke-width="15" :percentage="Number(specialty.jiuyelv1)" type="circle" />
          </div>
        </el-col>
        <el-col :span="8">
          <div class="box">
            <el-progress :stroke-width="15" :percentage="Number(specialty.jiuyelv2)" type="circle" />
          </div>
        </el-col>
        <el-col :span="8">
          <div class="box">
            <el-progress :stroke-width="15" :percentage="Number(specialty.jiuyelv3)" type="circle" />
          </div>
        </el-col>
      </div>

    </el-row>
    <p class="title" style="margin-top:10px">就业城市</p>
    <p v-if="specialty.city" class="intro-content">{{ specialty.city }}</p>
    <p v-else class="intro-content">暂无！</p>
  </div>
</template>

<script>
export default {
  name: "",
  props: {
    specialty: Object,
    num1: String,
    num2: String,
  },
  
  
  
  
};
</script>

<style scoped lang='less'>
.title {
  border-left: 5px solid #1787e0;
  padding-left: 15px;
}
.intro-content {
  color: #666666;
  padding: 30px 20px;
  font-size: 14px;
}
.box {
  border: 1px solid #f2f2f2;
  padding: 20px;

  .box-title {
    margin: 10px 0;
  }

  .percent {
    position: relative;
    top: -5px;
  }
}
</style>
